<template>
  <div v-if="client != false">
    <b-card class="card-custom mt-3 card-titre sticky-top" no-body style="z-index: 25;">
      <template #header>
        <div class="card-title">
          <h3 class="card-label p-3 bg-primary text-white rounded">Prospect <strong>#{{client.id}} - {{client.nom}} {{ client.prenom }}</strong> <small class="text-white text-sm d-none d-lg-inline" v-if="client.id != null">crée le {{ $api.timestampToDate(client.date_create, false) }}</small></h3>
        </div>
        <div class="card-title text-center">
          <b-button  :variant="$api.getParam('ClientStatus', client.status_id).color" size="lg">{{$api.getParam('ClientStatus', client.status_id).titre}}</b-button>
        </div>
        <div class="card-title">
          <b-button v-if="$api.acl(10)" v-b-tooltip.hover title="Duplication" variant="danger" class="btn-icon mr-1" v-b-modal.modalClone size="md">
            <i v-if="save_loader === false" class="fas fa-clone fa-size"></i>
            <i v-else class="fas fa-spinner fa-spin fa-size"></i>
          </b-button>
          <Activity v-if="client.id != null" :client_id="client.id" class="mr-1" />
          <Message v-if="client.id != null" :client_id="client.id" :phones="[client.telephone_1, client.telephone_2]" :obj="client" type="Client" class="mr-1"/>
          <Mail v-if="client.id != null" :client_id="client.id" :link="false" :obj="client" type="Client" class="mr-1"/>          
          <AgendaEvent model="Client" :model_id="client.id" :rdv="agenda" v-if="client.id != null" ref="agenda" class="mr-1"/>
          <b-dropdown v-if="$api.acl(17)" v-b-tooltip.hover title="Attribuer le prospect à un autre commercial" no-caret class="mr-1" size="md" variant="light-primary">
            <template #button-content>
              <i class="fas fa-user text-white fa-size"></i>
            </template>
            <b-dropdown-item v-for="(u,i) in $api.params.User" :key="i"  :active="client.user_id === u.id" @click="client.user_id = u.id">{{u.titre}}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown v-if="$api.acl(9)" v-b-tooltip.hover title="Changer le statut du prospect" no-caret class="mr-1" size="md">
            <template #button-content>
              <i class="fas fa-thermometer-half fa-size"></i>
            </template>
            <b-dropdown-item v-for="(s,i) in $api.params.ClientStatus" :key="i"  :active="client.status_id === s.id" @click="client.status_id = s.id">{{s.titre}}</b-dropdown-item>
          </b-dropdown>
          <b-button v-if="$api.acl(10)" v-b-tooltip.hover title="Sauvegarder" variant="success" class="btn-icon" @click="save()" size="md">
            <i v-if="save_loader === false" class="fas fa-save fa-size"></i>
            <i v-else class="fas fa-spinner fa-spin fa-size"></i>
          </b-button>
        </div>
      </template>
    </b-card>
    <form ref="form"> 
      <b-tabs class="mt-4" id="main-tabs" pills card align="center" v-model="tabIndex">
        <b-tab title="Information prospect">
          <b-card-group deck>
            <b-card class="card-search">              
              <b-row>     
                <b-col md="3">
                  <b-form-group label="Civilite">
                    <select v-model="client.civilite" class="form-control">
                      <option value="Mr">Mr</option>
                      <option value="Mme">Mme</option>
                    </select>
                  </b-form-group>
                </b-col>           
                <FieldForm model="Client" :obj="client" field="nom"/>
                <FieldForm model="Client" :obj="client" field="prenom"/>
                <b-col md="6">
                  <b-form-group label="Telephone 1:">
                    <Telephone v-model="client.telephone_1" :edit="true"/>
                  </b-form-group>
                </b-col>       
                <b-col md="6">
                  <b-form-group label="Telephone 2:">
                    <Telephone v-model="client.telephone_2" :edit="true"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Email:">
                    <b-input-group>
                      <template #append>
                        <b-button variant="light-danger" class="btn-icon"  size="md" :href="`mailto:${client.email}`"><i class="fa fa-envelope"></i></b-button>
                      </template>
                      <b-form-input v-model="client.email"></b-form-input>
                    </b-input-group>
                  </b-form-group>                  
                </b-col>                
                <FieldForm model="Client" :obj="client" field="adresse"/>
                <FieldForm model="Client" :obj="client" field="adresse_2"/>
                <CodePostal :obj="client" />
                <FieldForm model="Client" :obj="client" field="ville"/>                                
                <b-col md="6">
                  <b-form-group label="Date d'éffet:">
                    <b-input-group class="mt-3">
                      <input type="date" class="form-control" required v-model="client.date_effet" :min="$api.moment().format('YYYY-MM-DD')" :max="max_d_effet">
                      <b-input-group-append>
                        <b-button v-b-tooltip.hover title="Générer les tarifs" variant="light-primary" class="btn-icon" @click="checkForm()">
                          <i class="fas text-white" :class="tarifReady ? 'fa-spinner fa-spin' : 'fa-sync'"></i>                
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    
                  </b-form-group>
                </b-col>                           
                <FieldForm model="Client" :obj="client" field="source_id"/>
                <b-col md="6">
                  <b-form-group label="Commerciale:">
                    <input class="form-control" :value="$api.p('User', client.user_id).titre" disabled/>
                  </b-form-group>
                </b-col>
                <FieldForm model="Client" :obj="client" field="transformation_id"/>
              </b-row>
            </b-card>
            <b-card class="card-search">              
              <b-card-text class="text-center h6">Assuré principal</b-card-text>
              <b-row>                
                <FieldForm model="Client" :obj="client" field="regime_id"/>
                <FieldForm model="Client" :obj="client" field="date_naissance"/>
              </b-row>              
              <b-card-text class="text-center h6">Conjoint &nbsp;	&nbsp;	
                <b-button size="sm" class="btn-icon" @click="changeAdhs()" variant="warning">
                  <i class="fas fa-exchange-alt"></i>
                </b-button>
              </b-card-text>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Regime:">
                    <Input type="select-model" :required="client.conjoint.date_naissance != null" :options="$api.params.Regime" v-model="client.conjoint.regime_id"  placeholder="Regime"/>
                  </b-form-group>                  
                </b-col>
                <b-col md="6">
                  <b-form-group label="Date de naissance:">
                    <Input type="date" v-model="client.conjoint.date_naissance" :required="client.conjoint.regime_id != null"/>
                  </b-form-group>                  
                </b-col>
              </b-row>
              <b-card-text class="text-center h6">Enfants <b-button variant="primary" @click="addEnfant()" class="btn-icon" size="xs"><i class="fa fa-plus"></i></b-button></b-card-text>
              <b-row v-for="(e,i) in client.enfants" :key="i">                
                <b-col md="5">
                  <b-form-group label="Regime:">
                    <Input type="select-model" :required="true" :options="$api.params.Regime" v-model="e.regime_id"  placeholder="Regime"/>
                  </b-form-group>                  
                </b-col>
                <b-col md="5">
                  <b-form-group label="Date de naissance:">
                    <Input type="date" :required="true" v-model="e.date_naissance"/>
                  </b-form-group>                  
                </b-col>
                <b-col md="2">
                  <b-form-group label="Action:">
                    <b-button variant="danger" class="btn-icon" @click="removeEnfant(i)"><i class="fa fa-trash"></i></b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-block border-top mt-5 mb-2"></div>
              <b-row>                
                <FieldForm model="Client" :obj="client" field="actuelle_mutuelle"/>
                <b-form-group label="+12 Mois" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="client.actuelle_mutuelle_plus_12m" name="actuelle_mutuelle_plus_12m" :value="true">Oui</b-form-radio>
                  <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="actuelle_mutuelle_plus_12m" :value="false">Non</b-form-radio>
                </b-form-group>                
                <FieldForm model="Client" :obj="client" field="actuelle_cotisation"/>
                <FieldForm model="Client" :obj="client" field="actuelle_numero_adherent"/>
              </b-row>
              <div class="d-block border-top mt-5 mb-2"></div>
              <Parrain v-model="client.parrain_id" :parrain="parrain"></Parrain>
            </b-card>
          </b-card-group>          
        </b-tab>        
        <b-tab title="Tarificateur" v-if="client.id != null">
          <b-row>
            <b-col :md="selected.length > 0 ? 9 : 12">
              <Datatable ref="liste_tarif" :data_filters="filters" :route="`/client/price/liste/${client.id}?hospi=${filters_extrat.hospi}&responsable=${filters_extrat.responsable}`" :fields="fields" titre="Liste des tarifs" :sort="sort">      
                <template #customField>
                  <b-row>
                    <b-col md="5">
                      <b-form-group label="Par mutuelle">
                        <Input type="select-model" v-model="filters.mutuelle_id" :options="$api.params.Mutuelle" /> 
                      </b-form-group>
                    </b-col>
                    <b-col md="5">
                      <b-form-group label="Par garantie">
                        <Input type="select-model" v-model="filters.titre" :options="produitsFieltered" /> 
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-button-group vertical>
                        <b-button variant="light-danger" @click="filters.mutuelle_id = forteMarge; $refs.liste_tarif.datatableInit()">Forte marge</b-button>                                                  
                      </b-button-group>                                            
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Hospi">
                        <select class="form-control" v-model="filters_extrat.hospi">
                          <option :value="null">Tout</option>
                          <option :value="true">Oui</option>
                          <option :value="false">Non</option>
                        </select>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Responsable">
                        <select class="form-control" v-model="filters_extrat.responsable">
                          <option :value="null">Tout</option>
                          <option :value="true">Oui</option>
                          <option :value="false">Non</option>
                        </select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </template> 
                <template #action>
                  <b-button v-if="$api.acl(11)" v-b-tooltip.hover size="sm" title="Creer un devis" variant="success" class="btn-icon" @click="(createProject())">
                    <i class="fas text-white fa-plus"></i>                
                  </b-button>
                  <b-button v-b-tooltip.hover size="sm" title="Générer les tarifs" variant="light-primary" class="btn-icon ml-1" @click="(checkForm())">
                    <i class="fas text-white" :class="tarifReady ? 'fa-spinner fa-spin' : 'fa-sync'"></i>                
                  </b-button>
                </template>
                <template #cell(produit)="data">
                  <small v-for="(d, i) in getProduit(data.value)" :key="i" class="d-block">{{ d.key }}: <strong>{{ d.value }}</strong></small>
                </template>
                <template #cell(tarif)="data">
                  {{ $api.price(data.value) }}
                </template> 
                <template #cell(action)="data">
                  <b-form-checkbox v-model="selected" :key="data.item.id" :value="data.item" size="lg" inline >
                    <i class="fa fa-star text-warning" v-if="checkSelect(data.item.id)"></i>
                  </b-form-checkbox>              
                </template>          
                <template #cell(date_create)="data">
                  {{$api.timestampToDate(data.value)}}
                </template>     
              </Datatable>
            </b-col>
            <b-col md="3" v-if="selected.length > 0">
              <div class="sticky-top" style="margin-top:50px">
                <b-list-group>
                  <b-list-group-item v-for="(s, i) in selected" :key="i" href="javascript:void(0)">{{ s.titre }} <strong>{{ $api.price(s.tarif) }}</strong><b-button size="xs" class="btn-icon ml-4" variant="danger" @click="selected.splice(i, 1);"><i class="fa fa-trash"></i></b-button></b-list-group-item>                  
                </b-list-group>                
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Devis" v-if="projets.length > 0">
          <b-card class="card-search">
            <b-table-simple hover>    
              <b-thead>                
                <b-th>Ref</b-th>
                <b-th>Prix Min/Max</b-th>
                <b-th>Nb d'offres</b-th>
                <b-th>Details</b-th>
                <b-th>Statut</b-th>
                <b-th>Action</b-th>
                <b-th>Date de creation</b-th>                
              </b-thead>
              <b-tbody>
                <b-tr v-for="(p, i) in projets" :key="i">        
                  <b-td><span class="text-bolder">Devis #{{ p.id }}</span></b-td>
                  <b-td>{{ $api.price(getMinMax(p.quotes).min) }} - {{ $api.price(getMinMax(p.quotes).max) }}</b-td>
                  <b-td>{{ p.quotes.length }}</b-td>                  
                  <b-td><small v-for="(q, qi) in p.quotes" :key="qi" class="d-block">{{ q.titre }} - <strong>{{ $api.price(q.tarif) }}</strong></small></b-td>
                  <b-td class="text-center">
                    <b-dropdown v-b-tooltip.hover title="Changer le statut du devis" no-caret :variant="$api.p('ProjetStatus', p.status_id).color" size="md">
                      <template #button-content>
                        {{ $api.p('ProjetStatus', p.status_id).titre }} <i class="ml-2 fas fa-chevron-down"></i>
                      </template>
                      <b-dropdown-item v-for="(s,i) in $api.params.ProjetStatus" :key="i" :disabled="$api.params.StatusLock.devis_lock.includes(p.status_id)" :active="p.status_id === s.id" @click="changeStatus(p, s.id)">{{s.titre}}</b-dropdown-item>
                    </b-dropdown><br/>
                    <small v-if="p.contrat.date_validation != null">signé le {{ $api.moment(p.contrat.date_validation).format("DD/MM/YYYY") }}</small>    
                  </b-td>
                  <b-td><b-button :href="`/#/devis/detail/${p.id}`" size="sm" class="btn-icon" variant="outline-primary"><i class="fa fa-eye"></i></b-button></b-td>
                  <b-td>{{ $api.timestampToDate(p.date_create) }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-tab>
        <b-tab title="Modifications" v-if="client.id != null">
            <Log :logs="logs" />
        </b-tab>
      </b-tabs>
      <b-card class="mt-3">
        <Commentaire v-model="client.commentaire" />
      </b-card>
    </form>
    <b-modal id="modalClone" ref="modalClone" hide-footer title="Duplication">
      <b-card-text>Vous etes sur le point de <strong>dupliquer ce prospects</strong> </b-card-text>      
      <div class="d-block text-center mt-3">
        <b-button variant="light-primary" :class="save_loader ? 'btn-icon' : ''" @click="save('clone')">
          <span v-if="save_loader === false" class="text-white">Accepter</span>
          <i v-else class="fa fa-spin fa-spinner"></i>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import FieldForm from '@/components/FieldForm';
import Telephone from '@/components/Telephone';
import Commentaire from '@/components/Commentaire';
import AgendaEvent from '@/components/AgendaEvent';
import Input from '@/components/InputForm';
import Datatable from '@/components/Datatable';
import Message from '@/components/Message';
import Mail from '@/components/Mail';
import CodePostal from '@/components/CodePostal';
import Activity from '@/components/Activity';
import Parrain from '@/components/Parrain';
import Log from '@/components/Log';
export default {
  name: "ClientNouveau",
  components: {
    FieldForm,
    Commentaire,    
    AgendaEvent,    
    Telephone,
    Input,
    Datatable,
    Message,
    Mail,
    CodePostal,
    Activity,
    Parrain,
    Log
  },
  data(){
    var fields = [
      { key: 'id', label: 'Ref', sortable:false}
    ]
    if(this.$api.acl(11)){
      fields.push({ key: 'action', label: 'Action', sortable:false, });
    }
    fields = fields.concat([{ key: 'mutuelle.titre', label: 'Mutuelle', sortable:false, }, { key: 'titre', label: 'Titre', sortable:false, }, { key: 'produit', label: 'Recap', sortable:false, }, { key: 'tarif', label: 'Tarif', sortable:true, }, { key: 'date_create', label: 'Creation', sortable:true, }]);
    return{
      tabIndex:0,
      client: false,
      parrain: false, 
      agenda:[],  
      projets: [],
      logs: [],
      meta:[],   
      save_loader:false,
      tmpCommentaire:null,
      tarifReady:false,      
      filters:{
        titre:[],
        mutuelle_id:[]
      },
      fields: fields,
      sort:{
        key: 'tarif',
        value: 'ASC'
      },
      filters_extrat: {
        hospi: false,
        responsable: "null"
      },      
      selected:[]      
    }
  },
  watch:{
    'client.regime_id'(newV){
      if(newV === null){
        this.client.date_naissance = newV;
      }      
    },
    'client.conjoint.regime_id'(v){
      this.client.conjoint.regime_id  = v === undefined ? null : v;
      if(this.client.conjoint.regime_id === null){
        this.client.conjoint.date_naissance = null;
      }      
    }
  },
  computed:{
    forteMarge(){
      var ids = [];      
      this.$api.params.Mutuelle.forEach(e => {
        if(e.forte_marge === true){
          ids.push(e.id);
        }
      });
      return ids;
    },
    max_d_effet(){
      var next =  this.$api.moment().add(1, 'Y');
      return next.format('YYYY-MM-DD');
    },
    produitsFieltered(){
      var titres = [];
      if(this.filters.mutuelle_id.length === 0){
        titres = this.meta.titres
      }else{
        titres = this.meta.titres.filter(t => this.filters.mutuelle_id.includes(t.mutuelle_id));
      }                  
      return titres;
    }
  },
  beforeMount() {    
  },
  mounted() {
    if(this.$route.params.id === undefined){
      this.$api.ajax('/client/new', null, res => {
        if(res.status){
          this.client = res.data;
        }
      })
    }else{
      this.$api.ajax('/client/'+this.$route.params.id, null, res => {
        if(res.status){
          this.client = res.data.client;
          this.meta = res.data.meta
          this.projets = res.data.projets
          this.agenda = res.data.agenda;
          this.parrain = res.data.parrain;
          this.logs = res.data.logs;
        }
      });
    }
  },
  methods: {
    checkForm(){
      var form = this.$refs.form;
      var fields = form.querySelectorAll("[required='required']");
      var valide = true;
      fields.forEach((item) => {
        if(item.checkValidity() === false){
          item.classList.add("need-validation");
          valide = false;
        }else{          
          item.classList.remove("need-validation");
        };
      });
      if(valide === false){
        this.$bvToast.toast('Merci de remplir tout les champs en rouge', {
          title: 'Champs',
          variant: 'warning',
          solid: true
        });
        this.tabIndex = 0;
        return false;
      }
      this.save('price')
    },
    save(after = null){
      if(this.save_loader === true){
        return false;
      }      
      this.save_loader = true;
      this.$api.ajax('/client/create', this.client, res => {
        if(res.status === true){
          this.client = res.data;          
          if(this.$route.path.indexOf("nouveau") > -1){
            this.$router.push({path:"/prospect/detail/"+this.client.id});
          }
          this.$bvToast.toast('Sauvegarder', {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
        }
        this.save_loader = false;
        if(after === 'price'){
          this.getPrice();
        }else if(after === 'clone'){
          this.clone()
        }
        // if(after === null){
        //   if([1,2,3].includes(this.client.status_id)){
        //     this.$refs.agenda.open();
        //   }
        // }
      })
    },    
    addEnfant(){
      this.client.enfants.push({
        regime_id: null,
        date_naissance: null
      });
    },
    removeEnfant(i){
      this.client.enfants.splice(i, 1);
    },
    getPrice(){
      if(this.tarifReady === true){
        return false;
      }
      this.tarifReady = true;
      this.$api.ajax(`/client/price/${this.client.id}`, null, res => {
        if(res.status === true){
          this.tarifReady = false;
          this.$refs.liste_tarif.datatableInit();
          this.client = res.data.client;
          this.meta = res.data.meta
          this.projets = res.data.projets
          this.tabIndex = 1;
        }
      })
    },
    checkSelect(quote_id){
      return this.selected.filter(s => s.id === quote_id).length === 1;
    },
    getMinMax(quotes){
      var min = 15555555555;
      var max = 0;
      quotes.forEach(q => {
        if(q.tarif > max){
          max = q.tarif;
        }
        if(q.tarif < min){
          min = q.tarif;
        }
      });
      return {min: min, max:max};
    },
    createProject(){      
      if(this.selected.length === 0){
        this.$bvToast.toast('Merci de selectionnez au moin un produit', {
          title: 'Oups !',
          variant: 'warning',
          solid: true
        });
        return false;
      }
      var selected = [];
      this.selected.forEach(s => {
        selected.push(s.id);
      });
      this.$router.push({ path: `/devis/nouveau/${this.client.id}`, query: { quotes: selected } }) 
    },     
    getProduit(data){      
      if(data === false){
        return []
      }      
      var res = [];
      this.$api.params.StatusLock.tarificateur_preview.forEach(elt => {        
        var key = elt.a;
        var value = elt.b;        
        var check = data.garantie[key];        
        if(check != undefined){
          check = Object.values(check);
          var check2 = check.find( f => f.key === value);
          if(check2 != undefined){
            res.push(check2);
          }
        }
      });
      return res;
      // Object.values(data.garantie).forEach(g => {        
      //   var array = Object.values(g);        
      //    array = array.filter(q => q.value != null && q.value != '');
      //   if(array.length > 0 && res.length < 5){
      //     res.push(array[0]);
      //   }
      // });      
      // return res;
    },
    changeStatus(data, status){
      data.status_id = status;
      var params = {
        id: data.id,
        status_id: status
      }
      this.$api.ajax('/projet/create', params, res => {
        if(res.status === true){
          this.$bvToast.toast('Sauvegarder', {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
          //this.$refs.datatable.datatableInit();
        }
      })
    },  
    clone(){
      this.$api.ajax('/client/clone/'+this.client.id, null, res => {
        if(res.status === true){
          this.$router.push({path:'/prospect/detail/'+res.data.id});
          setTimeout(() => location.reload(), 500);
        }
      })
    },    
    changeAdhs(){
      let p_regime_id = this.client.regime_id;
      let p_date = this.client.date_naissance;
      let c_regime_id = this.client.conjoint.regime_id;
      let c_date = this.client.conjoint.date_naissance;
      this.client.regime_id = c_regime_id;
      this.client.date_naissance = c_date;
      this.client.conjoint.regime_id = p_regime_id;
      this.client.conjoint.date_naissance = p_date;
    },
    toggleHospi(){
      this.hospi = this.hospi ? false : true;
      setTimeout(() => { this.$refs.liste_tarif.datatableInit();}, 200);
    }
  }
}
</script>
<style>

#main-tabs__BV_tab_controls_ > .nav-item .nav-link.active{
  color: white !important;
}
#main-tabs__BV_tab_container_ > .tab-pane.card-body{
  padding: 0px;
  margin-top: 10px;
}
#main-tabs > .card-header:first-of-type {
  padding: 1rem 1.25rem !important;
}
.liste-custom {
  position: absolute;
  z-index: 9;
  width: 92%;
}
.select-pays select {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.need-validation {
  border: solid 2px red;
}
</style>
